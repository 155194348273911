<template>
    <div>
        <navbar />
        <main class="main-page">
            <div class="main-page__whats-app-link">
                <a
                    href="https://api.whatsapp.com/send/?phone=%2B963932086933&text&app_absent=0"
                >
                    <b-button
                        variant="light"
                        class="rounded-circle main-page__whats-app-button"
                    >
                        <unicon
                            name="whatsapp"
                            height="35"
                            width="35"
                            class="main-page__whatsapp-icon"
                        />
                    </b-button>
                </a>
            </div>
            <router-view></router-view>
        </main>
        <AFooter />
    </div>
</template>
<script>
import navbar from "@/views/website/layout/navbar/index.vue";
import AFooter from "@/views/website/layout/A-footer/index.vue";
export default {
    components: {
        navbar,
        AFooter
    }
};
</script>

<style lang="scss">
@import "/src/@core/scss/base/bootstrap-extended/include.scss";
body {
    background-color: var(--custom-dark) !important;
}
main {
    min-height: 500px;
}

.main-page {
    position: relative;

    &__whats-app-button {
        padding: 0.5rem;
    }

    &__whats-app-link {
        z-index: 1000;
        position: fixed;
        bottom: 2rem;
        left: 2rem;

        &:hover {
            cursor: pointer;
        }
    }
    &__whatsapp-icon {
        fill: green;
    }
}
</style>
