import { EventBus } from "./event-bus";

export default class globalEvent {
  static changeLang() {
    EventBus.$emit("onLangChange");
  }
  static changeDashLang() {
    EventBus.$emit("onDashLangChange");
  }
  static changeUserInfo() {
    EventBus.$emit("onChangeUserInfo");
  }
  static loadReequest() {
    EventBus.$emit("onLoadReequest");
  }
}
