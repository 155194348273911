<template>
    <b-navbar
        type="dark"
        variant="transparent"
        class="align-items-center py-2"
        style="z-index: 100"
        data-aos="fade-down"
        data-aos-duration="2500"
    >
        <b-container class="position-relative">
            <b-button
                variant="flat-secondary"
                class="btn-icon menu"
                @click="menu = true"
            >
                <img
                    src="/media/svg/menu-bars.svg"
                    alt="nav menu"
                    width="20"
                    height="20"
                />
            </b-button>
            <b-modal
                body-bg-variant="custom-dark"
                hide-header
                hide-footer
                centered
                size="lg"
                v-model="menu"
                modal-class="navbar-model"
                body-class="rounded border-white"
            >
                <div class="menu-header text-center">
                    <b-button
                        @click="menu = false"
                        variant="flat-secondary"
                        class="times-btn btn-icon position-absolute"
                    >
                        <unicon
                            name="times"
                            width="22"
                            height="22"
                            fill="#fff"
                        />
                    </b-button>
                    <b-img
                        src="/media/logo/full-logo-white.svg"
                        style="height:40px; "
                    >
                    </b-img>
                </div>
                <div
                    class="d-flex flex-column justify-content-center menu-nav-container"
                >
                    <ul class="m-auto p-0 list-unstyled menu-items">
                        <router-link
                            to="/"
                            custom
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <a
                                :active="isActive && isExactActive"
                                :href="href"
                                @click="navigate"
                            >
                                <li
                                    class="nav-item"
                                    :class="{
                                        active: isActive && isExactActive
                                    }"
                                >
                                    {{ t("website.home") }}
                                </li>
                            </a>
                        </router-link>
                        <router-link
                            to="/services"
                            custom
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <a
                                :active="isActive && isExactActive"
                                :href="href"
                                @click="navigate"
                            >
                                <li
                                    class="nav-item"
                                    :class="{
                                        active: isActive && isExactActive
                                    }"
                                >
                                    {{ t("website.services") }}
                                </li>
                            </a>
                        </router-link>
                        <b-nav-item-dropdown dir="ltr" class="dropdown-product">
                            <template #button-content>
                                {{ t("website.ourproducts") }}
                            </template>
                            <router-link
                                to="/categories"
                                custom
                                v-slot="{
                                    href,
                                    navigate,
                                    isActive,
                                    isExactActive
                                }"
                            >
                                <a
                                    :active="isActive && isExactActive"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <li
                                        class="nav-item"
                                        :class="{
                                            active: isActive && isExactActive
                                        }"
                                    >
                                        {{ t("website.categories") }}
                                    </li>
                                </a>
                            </router-link>
                            <router-link
                                to="/products"
                                custom
                                v-slot="{
                                    href,
                                    navigate,
                                    isActive,
                                    isExactActive
                                }"
                            >
                                <a
                                    :active="isActive && isExactActive"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <li
                                        class="nav-item"
                                        :class="{
                                            active: isActive && isExactActive
                                        }"
                                    >
                                        {{ t("website.allproducts") }}
                                    </li>
                                </a>
                            </router-link>
                        </b-nav-item-dropdown>

                        <router-link
                            to="/contactus"
                            custom
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <a
                                :active="isActive && isExactActive"
                                :href="href"
                                @click="navigate"
                            >
                                <li
                                    class="nav-item"
                                    :class="{
                                        active: isActive && isExactActive
                                    }"
                                >
                                    {{ t("website.contactus") }}
                                </li>
                            </a>
                        </router-link>
                        <router-link
                            to="/aboutus"
                            custom
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <a
                                :active="isActive && isExactActive"
                                :href="href"
                                @click="navigate"
                            >
                                <li
                                    class="nav-item"
                                    :class="{
                                        active: isActive && isExactActive
                                    }"
                                >
                                    {{ t("website.aboutus") }}
                                </li>
                            </a>
                        </router-link>
                        <router-link
                            to="/activities"
                            custom
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <a
                                :active="isActive && isExactActive"
                                :href="href"
                                @click="navigate"
                            >
                                <li
                                    class="nav-item"
                                    :class="{
                                        active: isActive && isExactActive
                                    }"
                                >
                                    {{ t("website.activities") }}
                                </li>
                            </a>
                        </router-link>
                    </ul>
                </div>
            </b-modal>
            <b-button
                to="/cart"
                variant="flat-secondary"
                class="btn-icon mx-md-1 position-relative"
            >
                <b-badge variant="custom-blue-sky" class="mr-1 cart-count">{{
                    cartCount
                }}</b-badge>
                <img
                    class="shopping-cart"
                    src="/media/svg/shopping-cart.svg"
                    alt="shopping cart"
                    width="20"
                />
            </b-button>
            <b-navbar-brand
                class="mx-auto d-none d-md-inline text-nowrap"
                href="/"
            >
                <transition name="fade">
                    <img
                        src="/media/logo/full-logo-white.svg"
                        class="position-absolute"
                        style="top: 0; left: 50%; transform: translate(-50%)"
                        v-if="!title"
                        height="40"
                    />
                    <span
                        class="position-absolute"
                        v-else
                        style="top:0; left: 50%; transform: translate(-50%)"
                        >{{ t(`website.${pageTitle}`) }}</span
                    >
                </transition>
            </b-navbar-brand>
            <locale />
            <b-button
                @click="resion = true"
                variant="flat-secondary"
                class="btn-icon mx-md-1"
            >
                <gb-flag :code="locate.countryCode" size="mini" />
            </b-button>
            <b-modal
                body-bg-variant="custom-dark"
                hide-header
                hide-footer
                centered
                size="lg"
                v-model="resion"
                modal-class="navbar-model"
                body-class="rounded border-white"
            >
                <div class="menu-header text-center">
                    <b-button
                        @click="resion = false"
                        variant="flat-secondary"
                        class="times-btn btn-icon position-absolute"
                    >
                        <unicon
                            name="times"
                            width="22"
                            height="22"
                            fill="#fff"
                        />
                    </b-button>
                    <b-img
                        src="/media/logo/full-logo-white.svg"
                        style="height:75px; "
                    >
                    </b-img>
                </div>
                <div
                    class="d-flex flex-column justify-content-center menu-nav-container pt-2"
                >
                    <EKInputText
                        name="search-flag"
                        :placeholder="$t('website.searchflag')"
                        v-model="flagName.value"
                    />
                    <b-row>
                        <b-col
                            cols="4"
                            md="3"
                            lg="2"
                            class="text-center"
                            v-for="(country, i) in countries"
                            :key="i"
                        >
                            <b-button
                                class="w-100 p-0 p-sm-1"
                                variant="flat-secondary"
                                @click="changeLocation(country)"
                            >
                                <gb-flag
                                    class="w-100"
                                    style="cursor: pointer"
                                    v-b-popover.hover.top
                                    :title="
                                        country.name +
                                            ' - ' +
                                            country.arabicName
                                    "
                                    loading="lazy"
                                    :code="country.code"
                                />
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
            <b-navbar-nav class="region">
                <b-nav-item-dropdown v-if="user.id" class="user-list" right>
                    <template #button-content>
                        <b-button
                            variant="flat-secondary"
                            class="btn-icon text-white pl-2 scale-reverse"
                        >
                            <img
                                src="/media/svg/user.svg"
                                alt="shopping cart"
                                width="20"
                                height="20"
                            />
                            <span class="d-none d-md-inline">{{
                                user.fullName
                            }}</span>
                        </b-button>
                    </template>
                    <router-link
                        :to="'/profile/' + user.id"
                        custom
                        v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                        <a
                            :active="isActive && isExactActive"
                            :href="href"
                            @click="navigate"
                        >
                            <li
                                class="nav-item text-nowrap"
                                :class="{ active: isActive && isExactActive }"
                            >
                                {{ t("website.profile") }}
                            </li>
                        </a>
                    </router-link>
                    <router-link
                        to="/auth-signin"
                        custom
                        v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                        <a
                            :active="isActive && isExactActive"
                            :href="href"
                            @click="navigate"
                        >
                            <li
                                class="nav-item text-nowrap"
                                :class="{ active: isActive && isExactActive }"
                            >
                                {{ t("website.logout") }}
                            </li>
                        </a>
                    </router-link>
                </b-nav-item-dropdown>
                <b-button
                    v-else
                    variant="flat-secondary"
                    class="btn-icon text-white"
                    @click="$router.push('/auth-signin')"
                >
                    {{ t("website.signin") }}
                </b-button>
            </b-navbar-nav>
        </b-container>
    </b-navbar>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapActions, mapGetters, mapState } from "vuex";
import locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import EKInputText from "@/EK-core/components/EK-forms/EK-input-text";
import jwt from "@/auth/jwt/useJwt";
import { getCookie, setCookie } from "@Ekcore/util/global";

export default {
    data: () => ({
        menu: false,
        resion: false,
        title: false
    }),
    components: {
        locale,
        EKInputText
    },
    computed: {
        ...mapGetters(["countries"]),
        ...mapState({
            cartCount: state => state.webCart.cartCount,
            user: state => state.websiteAccounts.user,
            flagName: state => state.global.flagName,
            locate: state => state.global.locate
        }),
        pageTitle() {
            return this.$route.meta(this.$route).title;
        }
    },
    setup() {
        const { t } = useI18nUtils();
        return { t, jwt };
    },
    created() {
        if (getCookie("awijaCart") == null) {
            setCookie("awijaCart", "", 1);
        }
        setTimeout(() => {
            this.getCartCount();
            this.fetchCountries();
            this.$store.commit("SET_TOKEN");
        });
        setTimeout(() => {
            this.title = true;
        }, 2400);
        this.getLocation();
    },
    methods: {
        ...mapActions(["fetchCountries", "getCartCount", "getLocation"]),
        changeLocation(c) {
            localStorage.setItem("locate", c.code);
            this.$store.commit("Set_Locate", {
                countryCode: c.code.toLowerCase()
            });
            this.resion = false;
        }
    },
    watch: {
        "$route.name"() {
            this.menu = false;
            this.title = false;
            setTimeout(() => {
                this.title = true;
            }, 2400);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";
::v-deep .nav-link.dropdown-toggle {
    padding: 0;
}
.region ::v-deep .dropdown-menu {
    min-width: 6rem;
    max-height: 300px;
    overflow: auto;
}

.user-list {
    ::v-deep .dropdown-menu {
        background: var(--custom-dark);
        li {
            ::v-deep a {
                color: #fff;
            }
            color: #fff;
            display: block;
            padding: 20px 26px;
            border-top: solid 1px #fff;
            position: relative;
            transition: 0.3s;
            &:after,
            &:before {
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                background: transparent;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                transition: 0.3s;
            }
            &:hover,
            &.active {
                border-color: var(--custom-blue-sky);
                a {
                    background: transparent;
                    color: #fff;
                }
                &:after {
                    background: radial-gradient(
                        31.82% 31.82% at 72.35% 31.06%,
                        rgba(255, 255, 255, 0.82) 0%,
                        rgba(20, 186, 248, 0) 100%
                    );
                }
                &:before {
                    background: var(--custom-blue-sky);
                }
            }
        }
    }
}
.menu-header {
    position: relative;
    padding: 12px;
    color: #fff;
    .times-btn {
        top: 0;
        left: 0;
    }
}
.menu-nav-container {
    min-height: 400px;
    .menu-items {
        li {
            ::v-deep a {
                color: #fff;
            }
            color: #fff;
            display: inline-block;
            padding: 10px 30px;
            border-top: solid 1px #fff;
            white-space: nowrap;
            position: relative;
            transition: 0.3s;
            ::v-deep .dropdown-menu {
                background: var(--custom-dark) !important;
                min-width: 165px;
                margin-right: -34px;
                display: flex;
                flex-direction: column;
                li {
                    width: 100%;
                    padding: 16px;
                    text-align: center;
                    &:after,
                    &:before {
                        left: 10px;
                    }
                    a {
                        background: transparent;
                    }
                }
            }
            &:after,
            &:before {
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                background: transparent;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                transition: 0.3s;
            }
            &:hover,
            &.active {
                border-color: var(--custom-blue-sky);
                &:after {
                    background: radial-gradient(
                        31.82% 31.82% at 72.35% 31.06%,
                        rgba(255, 255, 255, 0.82) 0%,
                        rgba(20, 186, 248, 0) 100%
                    );
                }
                &:before {
                    background: var(--custom-blue-sky);
                }
            }
            @media screen and (max-width: 992px) {
                border-bottom: solid 1px #fff;
                border-top: none;
                margin: auto 8px;
                width: calc(50% - 16px);
            }
        }
    }
}
</style>
<style lang="scss">
.cart-count {
    position: absolute !important;
    display: block;
    height: 16px;
    left: 0;
    bottom: 23px;
    top: auto !important;
    background: #1ac9fbbd;
}
.dropdown-toggle::after {
    right: auto;
    left: 0;
    top: 16px;
    position: absolute;
}
.nav-link.nav-link.dropdown-toggle {
    padding: 0;
}
.modal.navbar-model {
    filter: drop-shadow(-4px 4px 26px rgba(0, 0, 0, 0.25))
        drop-shadow(4px -4px 32px rgba(255, 255, 255, 0.08));
    backdrop-filter: blur(7px);
}
[dir="ltr"] {
    .menu,
    .shopping-cart {
        transform: scaleX(-1);
    }
}
.menu-nav-container {
    .gb-flag {
        height: 60px;
        margin-bottom: 1rem;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
