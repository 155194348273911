<template>
  <div class="footer p-0">
    <img src="/media/musk/footer1.svg" v-if="$route && $route.name != 'website'" class="w-100" />
    <footer>
      <b-container>
        <b-row>
          <b-col cols="12" md="6" lg="4" class="order-1">
            <div
              class="site-map text-center text-md-left mx-auto py-4"
              style="max-width: 300px"
            >
              <h3>{{ t("footer.map") }}</h3>
              <ul class="list-unstyled p-0 m-0">
                <li><a href="/" class="text-white">{{ t("website.home") }}</a></li>
                <li><a href="/services" class="text-white">{{ t("website.services") }}</a></li>
                <li><a href="/ourproducts" class="text-white">{{ t("website.ourproducts") }}</a></li>
                <li><a href="/contactus" class="text-white">{{ t("website.contactus") }}</a></li>
                <li><a href="/aboutus" class="text-white">{{ t("website.aboutus") }}</a></li>
                <li><a href="/activities" class="text-white">{{ t("website.activities") }}</a></li>
                <li><a href="/categories" class="text-white">{{ t("website.categories") }}</a></li>
              </ul>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4" class="order-1">
            <div class="mx-auto py-4" style="max-width: 300px">
              <h4 class="text-center">{{ t("footer.areyouready") }}</h4>
              <ul class="list-unstyled p-0 m-0">
                <li>
                  <ul class="phone-list list-unstyled p-0 m-0">
                    <li dir="ltr" v-if="websiteSettings.mobileNumber">
                        <unicon
                            name="phone"
                            width="20"
                            height="20"
                            fill="#fff"
                        />    
                        {{ websiteSettings.mobileNumber }}
                    </li>
                    <li dir="ltr" v-if="websiteSettings.mobileNumber2">
                        <unicon
                            name="phone"
                            width="20"
                            height="20"
                            fill="#fff"
                        />
                        {{ websiteSettings.mobileNumber2 }}
                    </li>
                  </ul>
                </li>

                <li class="text-center">
                  <div v-if="websiteSettings.email" dir="ltr" class="email border-bottom py-1">
                    <unicon
                        name="envelope"
                        width="24"
                        height="24"
                        fill="#fff"
                    />
                    {{ websiteSettings.email }}
                  </div>
                  <ul class="list-unstyled media-list p-0 m-0">
                    <a v-if="websiteSettings.whatsapp" :href="'https://wa.me/' + websiteSettings.whatsapp">
                      <li>
                        <unicon
                          name="whatsapp"
                          width="24"
                          height="24"
                          fill="#fff"
                        />
                      </li>
                    </a>
                    <a v-if="websiteSettings.facebookLink" :href="websiteSettings.facebookLink">
                      <li>
                        <unicon
                          name="facebook-f"
                          width="24"
                          height="24"
                          fill="#fff"
                        />
                      </li>
                    </a>
                    <a v-if="websiteSettings.instagramLink" :href="websiteSettings.instagramLink">
                      <li>
                        <unicon
                          name="instagram"
                          width="24"
                          height="24"
                          fill="#fff"
                        />
                      </li>
                    </a>
                    <a v-if="websiteSettings.behanceLink && false" :href="websiteSettings.behanceLink">
                      <li>
                        <unicon
                          name="behance"
                          width="24"
                          height="24"
                          fill="#fff"
                        />
                      </li>
                    </a>
                  </ul>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col cols="12" lg="4" class="order-0 order-lg-3">
            <div class="mx-auto text-center py-1 d-flex align-items-start" style="max-width: 300px">
              <img src="/media/logo/full-logo-white.svg" class="w-100 mt-4"/>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </footer>
    <div class="mini-footer">
      <hr />
      <div class="footer-copyright">
        <div
          class="
            container
            overflow-hidden
            px-0
            flex-column-reverse flex-md-row
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <div class="copyright-txt" dir="rtl">
            <span class="pr-50"> Awija </span>
            <span>
              {{ new Date().getFullYear() }}
              {{ t("footer").rights }}
            </span>
          </div>
          <div class="powered mb-1 mb-md-0">
            <p>{{ t("footer").powered }}</p>
            <a href="https://www.elkood.com" target="_blank">
              <img
                loading="lazy"
                src="/media/images/footer/elkood-white.svg"
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapActions, mapState } from "vuex";
export default {
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  created() {
    this.getWebsiteSettings();
  },
  methods: {
    ...mapActions(["getWebsiteSettings"]),
  },
  computed: {
    ...mapState({
      websiteSettings: (state) => state.global.websiteSettings,
    }),
  },
};
</script>
<style scoped lang="scss">
.footer {
  section {
    img {
      margin: 0 -16px;
      width: calc(100% + 34px);
    }
  }
  footer {
    h4 {
      font-size: 24px;
      color: #fff;
      white-space: nowrap;
    }
    color: #fff;
    .phone-list {
      li {
        display: inline-block;
        padding: 10px;
        width: 50%;
        letter-spacing: 1px;
        text-align: center;
      }
    }
    .email {
      letter-spacing: 1px;
    }
    .media-list {
      li {
        display: inline-block;
        padding: 10px;
      }
    }
    .site-map {
      h3 {
        white-space: nowrap;
        color: #fff;
      }
      li {
        width: calc(100% / 3);
        display: inline-block;
        line-height: 40px;
      }
    }
  }
}

.mini-footer {
  background: var(--footerColor);
  padding: 1px 16px 12px;
  .footer_contact-us a {
    text-decoration: none;
    color: #fff;
  }
  .copyright-txt {
    display: inline-flex;
    float: left;
  }
  .footer-copyright .copyright-txt span {
    font-size: 14px;
    color: #ffffff;
  }
  .powered {
    display: inline-flex;
    float: right;
  }
  .powered p {
    color: white;
    align-self: center;
    margin: 0 10px;
  }
  .powered img {
    width: 80px;
    margin-bottom: 5px;
  }
}
</style>
